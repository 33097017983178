<script setup lang="ts">
// Берем данные напрямую из API - для фильтрации и сортировки постов
const storyblokApi = useStoryblokApi()

const { data: { stories } } = await storyblokApi.get('cdn/stories', {
  version: 'draft',
  starts_with: 'blog',
  sort_by: 'content.created_at:desc',
  per_page: 6,
  cv: 'latest',
  is_startpage: false,
  filter_query: {
    tag: {
      in: 2,
    },
  },
})

function formatViews(num: number) {
  const forms = ['просмотр', 'просмотра', 'просмотров']

  function getDeclension(n, forms) {
    n = Math.abs(n) % 100
    if (n >= 11 && n <= 14) {
      return forms[2]
    }
    const lastDigit = n % 10
    if (lastDigit === 1)
      return forms[0]
    if (lastDigit >= 2 && lastDigit <= 4)
      return forms[1]
    return forms[2]
  }

  if (num < 1000) {
    return `${num} ${getDeclension(num, forms)}`
  }

  const thousandValue = num / 1000
  const formatted = Number.isInteger(thousandValue)
    ? thousandValue.toString()
    : thousandValue.toFixed(1)

  return `${formatted} тыс. ${getDeclension(num, forms)}`
}
</script>

<template>
  <div class="w-full bg-[#0F0F0F]">
    <div
      class="max-w-[1012px] w-full px-4 mx-auto py-12 md:py-[110px] flex flex-col gap-6 md:gap-10 text-white"
    >
      <div class="flex flex-col gap-2 md:gap-4 justify-center">
        <h2 class="text-m-h2 md:text-h2 pb-2">
          Подкаст с лидерами рынка
        </h2>
        <p class="text-m-primary md:text-primary">
          Мы приглашаем руководителей клиник из ТОП-200 Vademecum и обсуждаем
          эффективные маркетинговые идеи, проверенные на практике, смотрите
          свежие выпуски в
          <NuxtLink
            class="text-blue-500 cursor-pointer hover:opacity-80 duration-150"
            to="/blog"
          >
            нашем блоге
          </NuxtLink>.
        </p>
      </div>
      <div class="grid grid-cols-1 grid-rows-3 md:grid-cols-3 md:grid-rows-2 md:gap-x-12 gap-y-8">
        <NuxtLink
          v-for="(story, idx) in stories" :key="story.id" :class="{
            'hidden md:block': idx >= 3,
          }" :to="story.full_slug"
        >
          <div class="flex flex-col gap-1 hover:opacity-80 duration-150">
            <NuxtImg
              
              placeholder
              loading="lazy"
              :src="story.content.article_img.filename"
              class="rounded-[10px]"
              :alt="`${story.name} image`"
            />
            <h1 class="mt-2 text-primary-16 h-13 line-clamp-2 font-semibold" v-text="story.name" />
            <p v-if="story.content.views" class="text-secondary-500 text-primary-small" v-text="formatViews(story.content.views)" />
          </div>
        </NuxtLink>
      </div>
      <NuxtLink to="/blog?tag=2">
        <RButton class="w-fit" color="dark">
          Все подкасты
        </RButton>
      </NuxtLink>
    </div>
  </div>
</template>
